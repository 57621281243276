import { LatLngTuple } from "leaflet";


const latLngDist = ([lat1, lon1] : LatLngTuple, [lat2, lon2] : LatLngTuple) => {
    const φ1 = lat1 * Math.PI/180, φ2 = lat2 * Math.PI/180, Δλ = (lon2-lon1) * Math.PI/180, R = 6371e3;
    return Math.acos( Math.sin(φ1)*Math.sin(φ2) + Math.cos(φ1)*Math.cos(φ2) * Math.cos(Δλ) ) * R;
  }
  
const getClosestPoints = (p:LatLngTuple,points : {localisation:LatLngTuple,id:string}[]) => {
return points.map(({localisation,id}) => ({localisation,id,distance:latLngDist(localisation,p)})).sort( (a,b) => a.distance - b.distance)
}

  export {latLngDist, getClosestPoints}