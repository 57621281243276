import { Link } from 'react-router-dom';
import './App.css';
import Logo from './components/Logo';

const FirstPage = () => <div className="FirstPage">
    <div><Logo></Logo></div>
    <div className='content'>
        <div><Link to={`/powstanie`}>Gra Powstanie w Getcie</Link></div>
        <div className='divider'></div>
        <div><Link to={`/przewodnik`}>Audio przewodnik</Link></div>
        <div className='divider'></div>
        <div><a href='https://1943.pl' target={"_blank"}>Strona www muzeum</a></div>
    </div>
    <div>polityka prywatnosci</div>
</div>

export {FirstPage}