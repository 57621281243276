import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

import './App.css';
import { BottomBar } from './components/BottomBar';
import {iPoint, Map} from './components/Map'
import { ChatPanel } from './components/ChatPanel';
import { divIcon, LatLngTuple, Map as MapClass } from 'leaflet';

import { getClosestPoints } from './utils/latlngutils';
import { useInitOnce } from './utils/utilhooks';

import avatar1 from './assets/avatar1.png'
import { getGameLocalisations, getGameItems, iGamePoint, iGameItem, iConfig, getConfig, findItemFromId } from './utils/dialogueDataAccess';
import {  set as setInventory, useInventory } from './utils/inventory';

import GeneralMapApp from './components/GeneralMapApp';
import cameraicon from './assets/cameraicon.svg'

import { usePhotoInventory } from './utils/photoinventory';
import { useMap } from 'react-leaflet';
import { useFullscreen } from './components/FullscreenPicture';
import { ConfigProvider, useConfig } from './utils/useconfig';


const photoIcon = divIcon({className:"photoIcon",html:`<img src="${cameraicon}"/>`})

const gameName = "powstanie"
const gameLang = "PL"


const center : LatLngTuple = [52.25184771386281, 20.9960406459868]

function MapPhotoPopup({point}:{point:iGamePoint}){
  const map = useMap()
  const [isPhotoCollected,dispatch] = usePhotoInventory(store => store.find(item => item.picture == point.picture))  
  const [_,setFullscreen] = useFullscreen()

  const collectPhotoHandler = () => {
    map.closePopup()
    dispatch({type:"take",item:{picture:point.picture ?? "",description:point.description}})
    setFullscreen("games/powstanie/anim/banka_wpada.mp4")
  }
  return isPhotoCollected !== undefined
  ? <div className='MapPopup'><img src={point.picture}></img><div><p>Zebrane!</p></div></div>
  : <div className='MapPopup' onClick={collectPhotoHandler}><img src={point.picture}></img><div><p>Nowe</p><p>zdjęcie</p></div></div>
}

function MapDialoguePopup({point,onClick}:{point:iGamePoint,onClick:()=>void}){
  const map = useMap()
  const clickHandler = () => {
    onClick();
    map.closePopup()
  }
  return  <div className='MapPopup' onClick={clickHandler}><img src={point.picture}></img><div><p>Masz</p><p>wiadomość</p></div></div>
}



function ChatApp() {
  const [userPos, setUserPos] = useState(center)
  const [choosenPoint,setChoosenPoint] = useState("")
  const [closeToPoint, setCloseToPoint] = useState<ReturnType<typeof getClosestPoints>[number] | null>(null)

  const [points, setPoints] = useState<iPoint[]>([])
  const [config, setConfig] = useConfig()

  //const [avatar, setAvatar] = useState<string | null>(null)
  const [initialData,setInitialData] = useInitialData()
  const [visitedPoints, setVisitedPoints] = useState(new Set())
  const [outroShowed,setOutroShowed] = useState(false)
  const [_,setFullscreen] = useFullscreen()

  const mapRef = useRef<MapClass | null>(null)
  let chatPanels : React.ReactNode = []

  const hideChat = ()=> {
    //tutaj odpalamy outro jak mamy 8 odwiedzonych miejsc
    //console.log(visitedPoints.size, visitedPoints)

    if(visitedPoints.size === 8 && !outroShowed && config?.outro_movie){
      setFullscreen({src:config.outro_movie,onClose:()=>setOutroShowed(true)})
    }

    setChoosenPoint("")
    setCloseToPoint(null)
  }

  const mapClickHandler = (ev:any) => {
    const latlngtuple = [ev.latlng.lat, ev.latlng.lng] as LatLngTuple
    const closestPoint = getClosestPoints(latlngtuple,points)[0]
    setUserPos(ev.latlng)
    setCloseToPoint(closestPoint.distance < 30 ? closestPoint : null) 
  }

  const visitPoint = (pointid:string) => {
    setChoosenPoint(pointid)
    setVisitedPoints(set=>set.add(pointid))
  }

  const centerOnUserClickHandler = () => {
    if(mapRef.current === null)return;
   mapRef.current.panTo(userPos)
  }

  const initialized = useInitOnce(
    async () => {

      const points : iGamePoint[] = (await getGameLocalisations(gameName,gameLang)) ?? []

      setPoints(points.map(p=>
        p.type == "photo" 
        ? {...p, icon: photoIcon, Popup: <MapPhotoPopup point={p} />} 
        : {...p, Popup: <MapDialoguePopup point={p} onClick={()=>visitPoint(p.id)} /> }
      ))

      const items = (await getGameItems(gameName,gameLang)) ?? []
      const config = (await getConfig(gameName,gameLang)) ?? []

      

      config?.first_item && (config.first_item = findItemFromId(config.first_item,items))
      config.loaded = true;
      setConfig(config)
      
      //setInventory({type:"init",item:items[0]})
    }
  )

  chatPanels = (points as iGamePoint[]).filter(p=>p.type!=='photo').map(point=>
    <ChatPanel meavatar={initialData.avatar} point={point} key={point.id} visible={choosenPoint==point.id} clickOutsideHandler={hideChat}></ChatPanel>
    )

  console.log('ChatApp render')
  return  (
      <GeneralMapApp points={points} center={center} userPos={userPos} mapClickHandler={mapClickHandler} mapRefCallback={(ref:MapClass)=>mapRef.current = ref}>
        <React.Fragment>
        {config?.loaded ? <IntroModalSequence initialData={initialData} setInitialData={setInitialData}/> : null} 
        {chatPanels}
        </React.Fragment>
        <BottomBar avatar={initialData.avatar} onAvatarClick={centerOnUserClickHandler}></BottomBar>
      </GeneralMapApp>
  ) ;
}

function IntroModalSequence({initialData, setInitialData}:{
  initialData : ReturnType<typeof useInitialData>[0],
  setInitialData: ReturnType<typeof useInitialData>[1]
}){

  //const [initialData, setInitialData] = useInitialData() //firstVisit, avatar, geolocationWasSuccesful
  const [inventoryIsEmpty, setInventory] = useInventory(store => store.length == 0)
  const [photoInventoryIsEmpty] = usePhotoInventory(store => store.length == 0)
  const isLaunchedFromHomescreen = launchedFromHomeScreen()
  const [skipPhotoIntro, setSkipPhotoIntro] = useState(false)
  const [_,setFullscreen] = useFullscreen()
  const [config] = useConfig()

  const tryGeolocation = () => {
    setInitialData({type:"GEOLOCATION_SUCCESS"})
  }


  try {
    if(initialData.firstVisit && config?.intro_movie) {
      setInitialData({type:"FIRST_VISIT"})
      setFullscreen({src:config.intro_movie})
      return null;
    } 
  } catch(err){
    console.log(err)
  }

  if(!initialData.avatar && config?.avatars?.length) return <AvatarChoiceModal avatars={config.avatars} onChoice={(avatar:string)=>setInitialData({type:"AVATAR",avatar})} />
  if(inventoryIsEmpty) return <InventoryInfoModal onClick={()=>setInventory({type:"init",item:config?.first_item})} />
  if(photoInventoryIsEmpty && !skipPhotoIntro)return <PhotoInventoryInfoModal onClick={()=>setSkipPhotoIntro(true)} />
  if(!initialData.geolocationWasSuccesful)return <GeolocationNeededModal onClick={()=>tryGeolocation()} />
  //if(!isLaunchedFromHomescreen)return <InstallAppModal />

  return null;
}

function AvatarChoiceModal({avatars,onChoice}:{avatars:string[],onChoice:(avatar:string)=>void}){
  return <OneButtonModal title='Wybierz avatar'>
    {
      avatars.map(avatar => <div key={avatar} className='avatarChoiceItem' onClick={()=>onChoice(avatar)}><img src={avatar}></img></div>)
    }
  </OneButtonModal>
}

function InventoryInfoModal({onClick}:{onClick:()=>void}){
  return <OneButtonModal title ="Pierwsze zadanie" buttonTitle='Dalej' buttonClick={onClick}>
    Twoje pierwsze zadanie to zanieść kubek do najblizszego punktu.
  </OneButtonModal>
}

function PhotoInventoryInfoModal({onClick}:{onClick:()=>void}){
  return <OneButtonModal title ="Zbieraj zdjęcia" buttonTitle='Dalej' buttonClick={onClick}>
    Po drodze natrafisz na punkty oznaczone [ikona], to zdjęcia, które mozesz zbierać
  </OneButtonModal>
}



function GeolocationNeededModal({onClick}:{onClick:()=>void}){
  return <OneButtonModal title ="Potrzebny dostęp" buttonTitle='Dalej' buttonClick={onClick}>
    Do prawidłowego działania gry potrzebujemy dostęp do informacji o Twojej lokalizacji
  </OneButtonModal>
}

function OneButtonModal({children,title,buttonTitle,buttonClick,outsideClick}:{
  children:ReactNode,
  title?:string,
  buttonTitle?:string,
  buttonClick?:()=>void,
  outsideClick?:()=>void
}){
  return <Modal>
    {title ? <h2>{title}</h2> : null }
    <div>{children}</div>
    {
      buttonTitle ? <div className='Buttons'><RedButton text={buttonTitle} onClick={buttonClick ?? (()=>{})} /></div> : null
    }
  </Modal>
}

function Modal({children}:{children:ReactNode}){
  return <div className='ModalPanel'>
    <div className='ModalDialogue'>
      <div className='Content'>
       {children}
      </div>
    </div>
  </div>
}


const RedButton = ({text,onClick}:{text:string,onClick:()=>void}) => <JustButton onClick={onClick}>{text}</JustButton>
const GrayButton = ({text,onClick}:{text:string,onClick:()=>void}) => <JustButton onClick={onClick} className='GrayButton'>{text}</JustButton>
const JustButton = ({children,onClick,className="RedButton"}:{className?:string,children:ReactNode,onClick:()=>void}) => <div className={className} onClick={onClick}>{children}</div>


interface iInitialDataAction {
  type: "AVATAR" | "FIRST_VISIT" | "GEOLOCATION_SUCCESS",
  avatar?:string
}

function useInitialData(){
  const initialState = {
    avatar:"",
    firstVisit:true,
    geolocationWasSuccesful:false
  }
  const reducer = useCallback((state: typeof initialState,action:iInitialDataAction):typeof initialState =>{
    switch(action.type){
      case "AVATAR": return {...state,avatar:action.avatar ?? ""}
      case "FIRST_VISIT": return {...state,firstVisit:false}
      case "GEOLOCATION_SUCCESS": return {...state,geolocationWasSuccesful:true}
    }
  },[])
  return useReducer(reducer,initialState)
}

function launchedFromHomeScreen(){
  return false;
}

export default ChatApp;


