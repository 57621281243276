import { LatLngExpression, LatLngTuple, DivIcon, popup, divIcon,  Map as MapClass, icon, Icon, Point } from 'leaflet'
import { ReactPropTypes, useMemo, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl, useMapEvent } from 'react-leaflet'


interface iPoint {
    localisation:LatLngTuple,
    id:string,
    type?:string,
    name?:string,
    icon?:Icon | DivIcon,
    Popup?: React.ReactNode
}


const userIcon = divIcon({className: "userOnMapIcon"})

const XMarker = (
    {point, onClick} : {
        point:iPoint,
        onClick?: (arg:any) => void
    } ) => {
    
    //useMapEvent('click', (ev) => console.log('test ',ev)  )
    //const markerIcon = icon({iconUrl:cameraicon, iconRetinaUrl: cameraicon, iconSize:[20,20]})

    return <Marker {...point.icon !== undefined ? {icon:point.icon} : {}} position = {point.localisation} eventHandlers={{click:onClick ?? (()=>null) }}>
        {
            point.Popup != undefined ? <Popup>{point.Popup}</Popup>
            : point.name ? <Popup>{point.name}</Popup> 
            : null
        }
    </Marker>
}

const ClickOnMap = ({onClick}:{onClick:(ev:any)=>void }) => {
    useMapEvent('click',(ev)=>onClick(ev) )
    return null;
}

const MapRef = ({callback}:{callback:(m:MapClass)=>void}) => {
    const map = useMap()
    callback(map)
    return null
}

const Map = ({points,center,userPos,onClick,mapRefCallback}:{
    points:iPoint[],
    center:LatLngExpression,
    userPos:LatLngExpression,
    onClick?:(ev:any)=>void,
    mapRefCallback?: (ref:MapClass)=>void
}) => {
    
    //const center : LatLngExpression = {lat: 52.25184771386281, lng: 20.9960406459868}
   
    
    //points[0].localisation
    //const [markers,setMarkers] = useState<LatLngExpression[]>([])

    return <MapContainer center={center} zoom={16} scrollWheelZoom={false} id="MapDiv" zoomControl={false}  >
        { mapRefCallback ? <MapRef callback={mapRefCallback}></MapRef> : null}
        { onClick ? <ClickOnMap onClick={onClick}></ClickOnMap> : null}
        <ZoomControl position="topright" />
        <TileLayer 
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
            eventHandlers={{
                click:(ev)=>{
                    //console.log(ev)
                }
            }}
        />
        {points.map(
            (point,i) => <XMarker point={point} key={i} onClick={onClick}></XMarker>
        )}
        <Marker position={userPos} icon={userIcon}><Popup>Tu jestes!</Popup></Marker>
    </MapContainer>
    
}

export {Map}
export type {iPoint}