import {useState, createContext, useContext, useCallback } from "react"


interface iFullscreenData {
    src : string,
    align?: "top" | "bottom" | "center",
    description?: string,
    onClose?: ()=>void
}

function useFullscreenState(){
    return useState<string | iFullscreenData|  null>(null)
}

const FullscreenContext = createContext<ReturnType<typeof useFullscreenState> | null>(null);


const useFullscreen = () => {
    const fullscreen = useContext(FullscreenContext)
    if(fullscreen === null) throw Error("use <InentoryProvider></InventoryProvider> on the top level to use the Inventory")
    return fullscreen
} 

const VideoElement = ({src,onEnded}:{src:string,onEnded?:()=>void}) => {
    return <video autoPlay={true} muted={true} onEnded={onEnded} src={src}></video>
    //playsinline=true
}

const FullscreenViever = () => {
    const [data,SetImgurl] = useFullscreen()
    

    if(data == null) return null;

    const {src:imgurl, align = "center", description = "", onClose = ()=>{}} = typeof data == "string" ? {src:data} : data
    const closeme = () => {
        SetImgurl(null);
        onClose();
    }
    
    //H.264-encoded MP4 files
    if(imgurl.endsWith('.webm') || imgurl.endsWith('.mp4')) return <div className="fullscreen"  onClick={closeme}><VideoElement src={imgurl} onEnded={closeme}></VideoElement></div>

    return <div className="fullscreen" onClick={closeme}>
        <img src={imgurl} style={{objectPosition:align}}></img>
        <div className="ImageDescription"><div>{description}</div></div>
    </div>
}

const FullscreenProvider = ({children}:{children: JSX.Element | JSX.Element[]}) => {
    const fullscreen = useFullscreenState()
    return <FullscreenContext.Provider value={fullscreen}>{children}</FullscreenContext.Provider>
}

const useClickToFullscreen = () => {
    const [_, setFullscreen] = useFullscreen()
    return (item:{picture:string, description?:string}, ev?:any) => {
        ev && ev.preventDefault();
        setFullscreen({src:item.picture,align:"center",description:item.description})
    }
}

export {useFullscreen,FullscreenProvider,FullscreenViever,useClickToFullscreen}
