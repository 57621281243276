import { LatLngTuple, Map as MapClass } from 'leaflet';
import { FullscreenViever } from "./FullscreenPicture"
import { Map, iPoint } from "./Map"

function GeneralMapApp({
    points, center, userPos, mapRefCallback, mapClickHandler, children
}:{
    points: iPoint[],
    center : LatLngTuple,
    userPos: LatLngTuple,
    mapRefCallback?: (ref:MapClass)=>void,
    mapClickHandler: (ev:any)=>void,
    children?: JSX.Element | JSX.Element[] 
}) {
    
    return (
      <div className="App">
          <FullscreenViever></FullscreenViever>
          <Map points={points} center={center} userPos={userPos} onClick={mapClickHandler} mapRefCallback={mapRefCallback}></Map>
          <div className='InterfaceContainer'>
            {children}
          </div>
      </div>
    );
  }
  
  export default GeneralMapApp;