import { iGameItem } from "./dialogueDataAccess"

import {createGlobalStore} from './createGlobalStore'

interface iInventoryAction {
    type: "take" | "give" | "init",
    item: iGameItem 
}


const reducer = (state: iGameItem[], action: iInventoryAction ) : iGameItem[] => {
    switch(action.type){
        case "init": 
            return state.length ? state : [action.item];
        case "take": 
            return [
                ...state,
                action.item
            ];
        case "give":
            return state.map(
                item => item.id == action.item.id ? {...item,isCompleted:true} : item
            );
    }
}

const Inventory : iGameItem[] = []

const {Provider:InventoryProvider, useStore:useInventory, get, set, notifySubscribers} = createGlobalStore(Inventory,reducer)



export {useInventory,InventoryProvider, get, set, notifySubscribers}
export type {iInventoryAction}