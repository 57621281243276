import React, { useEffect, useRef, useState } from 'react';
import MGW_PL_White  from './MGW_PL_White.png'
import './App.css';

import { LatLngExpression, LatLngTuple, Map as MapClass } from 'leaflet';

import { getClosestPoints } from './utils/latlngutils';
import { useInitOnce } from './utils/utilhooks';

import { getGameLocalisations, iGuidePoint } from './utils/guideDataAccess';
import GeneralMapApp from './components/GeneralMapApp';
import { GuidePanel } from './components/GuidePanel';


function AudioGuideApp() {
  const gameName = "audioprzewodnik"
  const gameLang = "PL"

  const center : LatLngTuple = [52.23311946855967, 20.9983312811346]

  const [userPos, setUserPos] = useState(center)
  const [choosenPoint,setChoosenPoint] = useState<iGuidePoint | null>(null)
  const [closeToPoint, setCloseToPoint] = useState<ReturnType<typeof getClosestPoints>[number] | null>(null)
  const [points, setPoints] = useState<iGuidePoint[]>([])
  
  const mapRef = useRef<MapClass | null>(null)

  const mapClickHandler = (ev:any) => {
    const latlngtuple = [ev.latlng.lat, ev.latlng.lng] as LatLngTuple
    const closestPoint = getClosestPoints(latlngtuple,points)[0]
    const selectedPoint = points.find(p=>p.id == closestPoint.id)

    setUserPos(ev.latlng)
    console.log(ev.latlng)
    if(closestPoint.distance < 30){
      setCloseToPoint(closestPoint)
      selectedPoint!==undefined && setChoosenPoint(selectedPoint)
      console.log(selectedPoint)
    } else {
      setCloseToPoint(null)
    }
  }
  
  /*
  const statusBarClickHandler = () => {
    if(closeToPoint === null) return;
    setChoosenPoint(closeToPoint.id)
  }
  */

  const centerOnUserClickHandler = () => {
    if(mapRef.current === null)return;
    mapRef.current.panTo(userPos)
  }


  useInitOnce(
    async () => {
      setPoints((await getGameLocalisations(gameName,gameLang)) ?? [])
    }
  )

  return (
  
    <GeneralMapApp points={points} center={center} userPos={userPos} mapClickHandler={mapClickHandler} mapRefCallback={(ref:MapClass)=>mapRef.current = ref}>
        <React.Fragment>
          {
              choosenPoint!==null ? <GuidePanel visible={true} point={choosenPoint} clickOutsideHandler={()=>setChoosenPoint(null)}></GuidePanel> : <div></div>
          }
          <div className="GuideBottomBar">AUDIOPRZEWODNIK <img src={MGW_PL_White}></img></div>
        </React.Fragment>
    </GeneralMapApp>
  );
}

export default AudioGuideApp;


